document.documentElement.classList.remove('no-js');

import SmoothScroll from 'smooth-scroll';
import Swiper, { Navigation, Autoplay, EffectCreative, Pagination } from 'swiper';
import Parallax from './components/Parallax';
import VideoPlayerOverlayer from './components/VideoPlayerOverlayer';
import AOS from 'aos';
import * as $ from 'jquery';
import InputMask from './components/InputMask';

const inputMask = new InputMask();
Swiper.use([Navigation, Autoplay, EffectCreative, Pagination]);
let videoPlayerOverlayer = new VideoPlayerOverlayer(".youtube-player", ".c-text-video-img-section__video-img-container");
let fileLabel = document.getElementById("fileLabel");
let iconMenuMobile = document.getElementById("iconMenuMobile");
let contentMenuMobile = document.getElementById("contentMenuMobile");
if (document.getElementById('parallaxTeamSection') != null) {
    const parallaxTeamSection = new Parallax(document.getElementById('parallaxTeamSection'));
}



let swiperTestimonials = new Swiper(".mySwiperTestimonials", {
    loop: true,
    autoHeight: true,
    slidesPerView: 1,
    freeMode: true,
    spaceBetween: 30,
    autoplay: {
        delay: 5000,
    },
    // pagination: {
    //     el: ".swiper-pagination",
    //     type: "custom",
    // },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        // when window width is <= 320px
        1200: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        // // when window width is <= 480px
        // 480: {
        //     slidesPerView: 2,
        //     spaceBetweenSlides: 20
        // },
        // // when window width is <= 640px
        // 640: {
        //     slidesPerView: 3,
        //     spaceBetweenSlides: 30
        // }
    }
});

let swiperRealisations = new Swiper(".mySwiperRealisations", {
    loop: false,
    autoHeight: true,
    initialSlide: 1,
    slidesPerView: 1,
    freeMode: true,
    observer: true,
    preventClickse: false,
    preventClicksPropagation: false,
    allowTouchMove: false,
    speed: 0,
});
let swiperRealisationsImgs;
let elementSwiperRealisationsImg;


var modalRealisations = document.getElementById("modal-realisations");
var closeModalBtn = document.getElementById("close-modal");
let closeModal = () => {
    modalRealisations.style.display = "none";
    elementSwiperRealisationsImg.style.display = "none";
    swiperRealisationsImgs.destroy(true, true);
    swiperRealisationsImgs = null
}

if (closeModalBtn != null) {
    closeModalBtn.addEventListener("click", function (event) {
        closeModal();
    })
}

if (modalRealisations != null) window.addEventListener("click", function (event) {
    if (event.target == modalRealisations) {
        closeModal();
    }
})

let linksToModalList = document.getElementsByClassName("c-gallery-section__container-item--click");

for (const element of linksToModalList) {
    let arg = element.getAttribute('data-arg');
    element.addEventListener("click", function (event) {
        openAndSetInfoOnModal(arg)
    })
}

function openAndSetInfoOnModal(index) {
    modalRealisations.style.display = "flex";
    let classNameSwiper = `.mySwiperRealisationsImgs${index}`;
    let nextElSwiper = `.swiper-button-next-SwiperRealisationsImgs${index}`;
    let prevElSwiper = `.swiper-button-prev-SwiperRealisationsImgs${index}`;
    let paginationSwiper = `.swiper-pagination-SwiperRealisationsImgs${index}`;
    swiperRealisationsImgs = new Swiper(classNameSwiper, {
        loop: true,
        autoHeight: true,
        slidesPerView: 1,
        freeMode: true,
        observer: true,
        observeParents: true,
        preventClickse: false,
        spaceBetween: 60,
        preventClicksPropagation: false,
        pagination: {
            el: paginationSwiper,
            type: "fraction",
        },
        navigation: {
            nextEl: nextElSwiper,
            prevEl: prevElSwiper,
        },
    });
    elementSwiperRealisationsImg = document.getElementsByClassName(`mySwiperRealisationsImgs${index}`)[0];
    elementSwiperRealisationsImg.style.display = "flex";
    swiperRealisations.slideTo(index);
}

var scroll = new SmoothScroll('a[href*="#"]', {
    ignore: '[data-scroll-ignore]',
    header: '[data-scroll-header]',
    customEasing: function (time) {
        return time < 0.5 ? 2 * time * time : -1 + (4 - 2 * time) * time;
    }
});

let changeNavBarStyle = function () {
    let navBar = document.getElementById("navBar")
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        document.getElementById("navBar").className = "c-nav c-nav--scrolled";
    } else {
        document.getElementById("navBar").className = "c-nav";
    }
}

let setCardsHeight = function () {
    let cards = document.getElementsByClassName('card-height-fix');
    if (cards != null) {
        let heightsList = []
        for (let card of cards) {
            card.style.minHeight = "auto";
            card.style.display = "flex";
            card.style.height = "auto";
            heightsList.push(card.clientHeight);
        }
        let greatestHeight = Math.max(...heightsList)
        for (let card of cards) {
            var media_query = 'screen and (min-width:769px)';
            var media_query_height = 'screen and (max-height: 650px)';
            var matched = window.matchMedia(media_query).matches;
            var matched_height = window.matchMedia(media_query_height).matches;
            if (matched) {
                card.style.height = (greatestHeight) + "px";
            } else {
                card.style.height = "100%";
                card.style.minHeight = "180px";
            }
        }
    }
};



let togglecontentMenuMobile = function () {
    iconMenuMobile.classList.toggle("c-icon-menu-mobile__content--change");
    contentMenuMobile.classList.toggle("c-nav__options-container-item-links-container--open");
};

let resetTogglecontentMenuMobile = function () {
    if (screen.width >= 640) {
        let classListIconMenuMobile = iconMenuMobile.classList;
        let classListContentMenuMobile = contentMenuMobile.classList;
        if (classListIconMenuMobile.contains("c-icon-menu-mobile__content--change") && classListContentMenuMobile.contains("c-nav__options-container-item-links-container--open")) {
            togglecontentMenuMobile();
        }
    }

};


window.addEventListener('scroll', function () { changeNavBarStyle() });
window.addEventListener('resize', function () { setCardsHeight() });
window.addEventListener('resize', function () { resetTogglecontentMenuMobile() });

document.addEventListener('load', function () {
    setCardsHeight();
}, false);

changeNavBarStyle();


$(".animateLink").on("mouseleave", function () {
    $(this).addClass("wasHovered");
});



if (iconMenuMobile != null) iconMenuMobile.addEventListener("click", function (event) {
    togglecontentMenuMobile();
});


if (fileLabel != null) fileLabel.addEventListener("click", function (event) {
    document.getElementsByClassName("c-form__input--file")[0].click();
});

var inputs = document.querySelectorAll(".c-form__input--file");

if (inputs != null) {
    for (let index = 0; index < inputs.length; index++) {
        const input = inputs[index];
        var labels = input.labels;
        var labelVal = labels[0].innerHTML;

        input.addEventListener("change", function (e) {
            var fileName = "";
            if (this.files && this.files.length > 1)
                fileName = (
                    this.getAttribute("data-multiple-caption") || ""
                ).replace("{count}", this.files.length);
            else fileName = e.target.value.split("\\").pop();

            if (fileName) input.labels[0].innerHTML = fileName;
            else input.labels[0].innerHTML = labelVal;
        });

        // Firefox bug fix
        input.addEventListener("focus", function () {
            input.classList.add("has-focus");
        });
        input.addEventListener("blur", function () {
            input.classList.remove("has-focus");
        });
    }
}

AOS.init({
    once: true,
});

