import Inputmask from 'inputmask'

export default class InputMask {

    constructor () {

        let phoneMasks = document.getElementsByClassName('js-phone-mask')

        Array.from(phoneMasks).forEach((item) => {
            Inputmask('(999) 999-9999').mask(item)
        })
    }
}
