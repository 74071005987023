// doc : https://dixonandmoe.com/rellax/
// to install : npm install rellax --save
import Rellax from 'rellax';
export default class Parallax {
    constructor(element) {
        console.log("wow");
        
        var rellax = new Rellax(element);

        console.log("wow");
    }
}
