import Inputmask from 'inputmask'

export default class InputMask {

    constructor(classNameIcon, classNameContainer) {

        var players = document.querySelectorAll(classNameIcon)

        var loadPlayer = function (event) {
            var target = event.currentTarget
            var container = document.querySelectorAll(classNameContainer)[0]
            var video = document.createElement('video')
            var sourceMp4 = document.createElement('source')
            var sourceOgg = document.createElement('source')
            // < video width = "400" controls>
            //     <source src="mov_bbb.mp4" type="video/mp4">
            //         <source src="mov_bbb.ogg" type="video/ogg">
            //             Your browser does not support HTML video.
            //         </>
            video.setAttribute('height', '100%');
            video.setAttribute('width', '100%');
            video.autoplay = true
            video.controls = true
            video.controlsList = "nodownload"
            sourceMp4.src = target.dataset.videoId
            sourceMp4.type = "video/mp4"
            video.appendChild(sourceMp4);
            target.style.display = "none"



            container.classList.remove('pristine')

            if (container.children.length) {
                container.replaceChildren(video, container.firstElementChild)
            } else {
                container.replaceChildren(video)
            }
        }

        var config = { once: true }

        Array.from(players).forEach(function (player) {
            player.addEventListener('click', loadPlayer, config)
        })
    }
}
